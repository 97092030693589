<template>
  <div class="fill-height" style="width:100%">
    <SearchHeader>
      <v-layout>
        <v-flex>
          <v-row class="">
            <v-col cols="12" md="4" class="mt-1">
              <v-combobox v-model="selectedOwners" :items="owners" item-text="name" item-value="id" label="Owner to display" multiple></v-combobox>
            </v-col>
            <v-col cols="10" md="4" class="mt-1" v-if="false">
              <v-text-field
                v-model="query"
                :placeholder="$t('search.teaser.form.query')"
                @keyup.native.enter="doSearch()"
                @keyup.native.esc="cancelSearch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="mt-1" v-if="user.role != 'calview'">
              <v-switch v-model="showTasks" @change="toggleShowTasks" class="ma-2" :label="$t('calendar.cal.showTasks')" dense hide-details></v-switch><br />
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </SearchHeader>

    <v-row class="fill-height">
      <v-col cols="12">
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
              {{ $t("calendar.cal.days.today") }}
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on }">
                <v-btn outlined color="grey darken-2" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>{{ $t("calendar.cal.viewRange.day") }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>{{ $t("calendar.cal.viewRange.week") }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>{{ $t("calendar.cal.viewRange.month") }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>{{ $t("calendar.cal.viewRange.vdays") }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>

        <v-sheet height="80vh">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="appointments"
            :event-color="getAppointmentColor"
            :now="today"
            :type="type"
            @click:event="showAppointment"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          ></v-calendar>

          <v-menu v-model="appointmentOverflow" :close-on-content-click="false" :activator="selectedElement" offset-x>
            <v-card min-width="350px" flat>
              <v-toolbar :color="selApp.color" dark>
                <v-toolbar-title v-html="selApp.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <template v-if="selApp.isOwner">
                  <v-icon left @click="editAppointment(selApp.id)">mdi-pencil</v-icon>
                  <v-icon @click="delAppointment(selApp.id)">mdi-delete</v-icon>
                </template>
                <template v-if="user.role != 'calview'">
                  <v-btn text @click="$router.push(`/tasks/${selApp.id}`)" v-if="selApp.type == 'task'">
                    <v-icon left>mdi-arrow-right-circle-outline</v-icon> {{ $t("calendar.cal.toTask") }}
                  </v-btn>
                  <v-btn text @click="$router.push(`/projects/${selApp.projectId}`)" v-if="selApp.projectId">
                    <v-icon left>mdi-arrow-right-circle-outline</v-icon> {{ selApp.project.name }}
                  </v-btn>
                  <v-btn text @click="$router.push(`/customers/${selApp.customerId}`)" v-if="selApp.customerId">
                    <v-icon left>mdi-arrow-right-circle-outline</v-icon> {{ selApp.customer.name }}
                  </v-btn>
                </template>
              </v-toolbar>
              <v-card-text>
                <div class="details">
                  <div>
                    <span v-if="selApp.owner"
                      ><strong>{{ selApp.owner.name }}</strong></span
                    >
                    <span v-if="selApp.start"
                      >Vom <strong>{{ new Date(selApp.start) | datetime }}</strong></span
                    >
                    <span v-if="selApp.end"
                      >Bis <strong>{{ new Date(selApp.end) | datetime }}</strong></span
                    >
                  </div>
                </div>
                <div class="body" v-html="selApp.body"></div>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="appointmentOverflow = false">
                  {{ $t("calendar.cal.close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
        <v-navigation-drawer v-model="appointmentEditor" absolute temporary right width="75%">
          <CreateAppointment ref="createAppointment" :onSuccess="loadEntries" :onClose="hideEditor" closeRef="." />
        </v-navigation-drawer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";

import { mapGetters } from "vuex";
import { formatMixin, formMixin, listMixin } from "@/helpers/mixins";

import SearchHeader from "@/components/_common/searchheader";
import CreateAppointment from "./appointmentcreate";

export default {
  mixins: [formatMixin, formMixin, listMixin],
  data: function() {
    return {
      focus: "",
      query: "",
      type: "month",
      typeToLabel: {
        month: this.$i18n.t("calendar.cal.viewRange.month"),
        week: this.$i18n.t("calendar.cal.viewRange.week"),
        day: this.$i18n.t("calendar.cal.viewRange.day"),
        "4day": this.$i18n.t("calendar.cal.viewRange.vdays"),
      },
      appointmentEditor: null,
      today: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      start: null,
      end: null,
      selApp: {},
      selectedElement: null,
      appointmentOverflow: false,
      appointments: [],
      owners: [],
      selectedOwners: [],
      showTasks: false,

      colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
      names: ["Meeting", "Holiday", "PTO", "Travel", "Event", "Birthday", "Conference", "Party"],
    };
  },
  components: {
    CreateAppointment,
    SearchHeader,
  },
  computed: {
    ...mapGetters(["profile", "settings", "user"]),
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
    filteredAppointment() {
      return this.appointments.filter((e) => {
        let q = this.query.trim().toLowerCase();
        return (
          this.selectedOwners.find((so) => so.id == e.owner.id) &&
          e.name
            .trim()
            .toLowerCase()
            .includes(q)
        );
      });
    },
  },
  created() {
    this.showTasks = (this.user.role != "calview" && this.profile.calShowTasks) || false;
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.$refs.calendar.scrollToTime("08:00");
  },
  watch: {
    start() {
      this.loadEntries(this.showTasks);
    },
  },
  methods: {
    toggleShowTasks(val) {
      this.saveShowTasksToProfile(val);
      this.loadEntries(val);
    },
    createAppointment: function() {
      this.$refs.editor.show(null);
    },
    loadEntries: function(withAppointments) {
      this.$store.dispatch("loading", true);
      let s = moment(this.start.date)
        .tz("Europe/Paris")
        .format();
      let e = moment(this.end.date)
        .tz("Europe/Paris")
        .format();
      let proms = [];

      proms.push(
        new Promise((resolve, reject) => {
          axios
            .get(`/api/appointments?from=${encodeURIComponent(s)}&to=${encodeURIComponent(e)}`)
            .then((response) => {
              let appointments = response.data;
              let that = this;
              appointments.forEach((e) => {
                e.type = "appointment";
                e.timed = true;
                e.start = that.formatDate(new Date(e.start), true);
                if (e.end) {
                  e.end = that.formatDate(new Date(e.end), true);
                } else {
                  e.end = e.start;
                }
                if (e.owner && !this.owners.filter((ee) => ee.id === e.owner.id).length) {
                  this.owners.push(e.owner);
                }
                e.color = "#" + this.intToRGB(this.hashCode(e.ownerId));
              });
              this.selectedOwners = this.owners;
              resolve(appointments);
            })
            .catch((err) => {
              reject(err);
            });
        })
      );

      if (withAppointments) {
        proms.push(
          new Promise((resolve, reject) => {
            axios
              .get(`/api/tasks?from=${encodeURIComponent(s)}&to=${encodeURIComponent(e)}&assignedtome=true`)
              .then((response) => {
                let tasks = response.data;
                let that = this;
                tasks.forEach((e) => {
                  e.type = "task";
                  e.start = that.formatDate(new Date(e.dueDate), true);
                  e.end = e.start;
                  e.timed = false;
                  e.color = "#" + this.intToRGB(this.hashCode(e.assigneeId));
                });
                this.selectedOwners = this.owners;
                resolve(tasks);
              })
              .catch((err) => {
                reject(err);
              });
          })
        );
      }

      Promise.all(proms)
        .then((values) => {
          this.appointments = [].concat.apply([], values);
        })
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch("loading", false);
    },
    editAppointment: function(id) {
      this.appointmentOverflow = false;
      this.$refs.createAppointment.load({ id: id });
      this.appointmentEditor = true;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getAppointmentColor(appointment) {
      return appointment.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showAppointment({ nativeEvent, event }) {
      const open = () => {
        this.selApp = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.appointmentOverflow = true), 10);
      };

      if (this.appointmentOverflow) {
        this.appointmentOverflow = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d > 3 && d < 21 ? "th" : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    hideEditor() {
      this.appointmentEditor = false;
    },
    delAppointment(appointmentId) {
      axios
        .delete(`/api/appointments/${appointmentId}`)
        .then(() => {
          this.$store.dispatch("showSnackMessage", { message: "Appointment deleted." });
          this.loadEntries();
          this.appointmentOverflow = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
    showEditor() {
      this.selApp = {};
      this.$refs.createAppointment.reset();
      this.appointmentEditor = true;
    },
    hashCode(str) {
      var hash = 0;
      if (str) {
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
      }
      return hash;
    },
    intToRGB(i) {
      var c = (i & 0x00ffffff).toString(16).toUpperCase();
      return "00000".substring(0, 6 - c.length) + c;
    },

    cancelSearch() {
      this.query = "";
    },
    doSearch() {
      this.$store.dispatch("showSnackMessage", { message: "Noch nicht implementiert" });
    },

    saveShowTasksToProfile: function(val) {
      let data = { ...this.user };
      data.profile.calShowTasks = val;
      this.$store
        .dispatch("setUser", data)
        .then(() => {
          //this.$store.dispatch("showSnackMessage", { message: "Data saved" });
        })
        .catch((e) => {
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>

<style lang="scss" scope>
.details {
  border-bottom: 1px solid #efefef;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;

  & span {
    padding-right: 1rem;
  }
}
</style>
