<template>
  <DefaultLayout>
    <template v-slot:header>
      <FilterHeader
        :items="[]"
        :onChange="onFilterChange"
        :usePreset="false"
      />
      <v-spacer></v-spacer>
      <AddButton v-if="role != 'calview'" :onClick="add" :selectedFilter="selectedFilter" />
    </template>
    <Calendar ref="cal" />
  </DefaultLayout>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultLayout from "@/layouts/default.vue";
import FilterHeader from "@/components/_common/filterheader";
import AddButton from "@/components/_common/addbutton";
import Calendar from "@/components/appointments/calendar.vue";

export default {
  name: "home",
  components: {
    DefaultLayout,
    FilterHeader,
    Calendar,
    AddButton,
  },
  data: function() {
    return {
      selectedFilter: 0,
    };
  },
  computed: {
    ...mapGetters(["role"]),
  },
  methods: {
    add: function() {
      this.$refs.cal.showEditor();
    },
    onFilterChange: function(val) {
      this.selectedRendering = val;
    },
  },
};
</script>
